
const SNFORMS = {
	onReady: () => {

		SNFORMS.selector = '.djht-form';
		const forms = document.querySelectorAll(SNFORMS.selector);

		if( forms.length < 1 )
			return;


		SNFORMS.initSpamProtection();
		SNFORMS.initPristine();

		forms.forEach( (form) => {
			form.addEventListener('submit', SNFORMS.initSubmit);
		} );
	},



	initSpamProtection: function(){
		let forms = document.querySelectorAll(SNFORMS.selector);

		forms.forEach( (form) => {
			if ( form.classList.contains('do-spam-protection') ) {
				let input = document.createElement('input');
				input.setAttribute('type', 'hidden')
				input.setAttribute('name', 'sn-form-start');
				input.setAttribute('value', Date.now());

				form.prepend(input);
			}
		});
	},




	initPristine: () => {
		let lang = document.documentElement.lang.slice(0,2) || 'en';

		Pristine.setLocale(lang);
		Pristine.addMessages(lang, localizeJS.pristineLang);
		
		SNFORMS.pristineConfig = {
			classTo: 'control',
        	errorClass: 'is-danger',
        	successClass: false,
        	errorTextParent: 'control',
        	errorTextClass: 'help is-danger'
		};

		const forms = document.querySelectorAll(SNFORMS.selector);
		
		SNFORMS.pristineUserEmail();
		SNFORMS.pristineRetype();
		SNFORMS.pristineMaxCount();
		
		forms.forEach( (form) => {
			SNFORMS.pristine = new Pristine(form, SNFORMS.pristineConfig);
		});
	},



	pristineRetype: () => {		
		Pristine.addValidator(
			"retype",
			function (value) {
				let source = document.getElementById(this.dataset.retypeSource);

				if (value == source.value){
			        return true;
			    }
			    
			    return false;
			},
			localizeJS.pristineLang.passwordmatch
		);
	},



	pristineUserEmail: () => {	
		Pristine.addValidator(
			"useremailexists",
			function (value) {
			  	fetch(snJS.restUrl + '/register/check-email/?email=' + value, {
					method: 'GET',
					credentials: "same-origin",
				})
				.then((response) => response.json())
				.then((response) => {

					if ( response == true )
						SNFORMS.pristine.addError(this, localizeJS.pristineLang.userEmailExists);
				});

				return true;
			},	
			""
		);
	},



	pristineMaxCount: () => {
		Pristine.addValidator(
		    'maxcount', 
		    function (value, num) { 
		    	return document.querySelectorAll('input[name="'+this.name+'"]:checked').length <= parseInt(num) 
		    }, 
		    localizeJS.pristineLang.maxSelected
		);
	},



	initSubmit: () => {
		SNFORMS.event 		= event;
		SNFORMS.context 	= SNFORMS.event.target;
		SNFORMS.submitter 	= event.submitter;

        SNFORMS.event.preventDefault();

    	// Errors on <details>
    	let details = SNFORMS.context.querySelectorAll('details');
    	details.forEach( (detail) => {
			if ( !detail.classList.contains('static-open') ) {
				detail.removeAttribute('open');
			}

    		detail.classList.remove('is-danger');
    	})
        

        // Remove Required validation (Events save)
        if ( SNFORMS.context.querySelector('#' + SNFORMS.event.submitter.getAttribute('id')).classList.contains('no-validate') ) {
        	SNFORMS.toggleAttrRequired();
        }



        let pristine = new Pristine(SNFORMS.context, SNFORMS.pristineConfig);
        if ( pristine.validate() || ! SNFORMS.context.classList.contains('djht-validate-pristine') ) {
	        // Event submission check
	        if ( SNFORMS.context.querySelector('#' + SNFORMS.event.submitter.getAttribute('id')).classList.contains('submission-check') ) {
	        	SNFORMS.initSubmissionCheck();
	        }

    		SNFORMS.context.querySelector('#' + SNFORMS.event.submitter.getAttribute('id')).classList.add('is-loading');
    		SNFORMS.sendForm();
    	
    	// Show errors
    	} else {
    		let details = SNFORMS.context.querySelectorAll('details'); 
    		let errors  = document.querySelectorAll('.pristine-error');
    		let errorElements = [];

    		// if ( details == null || details.length == 0 )
			// 	return;


    		details.forEach( (detail) => {
    			if ( !detail.classList.contains('static-open') ) {
    				detail.removeAttribute('open');
    			}
    		});
    		
    		var $i = 0;
    		errors.forEach( (error) => {
    			if ( error.textContent !== null && error.textContent != '' ) { // only visible Elements
    				if ( details == null || details.length == 0 && $i == 0) { // first error element if no details element
    					error.scrollIntoView();

						$i++;
    				}

    				if ( error.closest('details') != null ) {
	    				error.closest('details').setAttribute('open', '');
	    				error.closest('details').classList.add('is-danger');
	    				errorElements.push(error);     					
    				}
    			}
    		});

    		if ( errorElements.length >= 1 ) // only visible Elements
    			errorElements[0].scrollIntoView(false);

    	}


        // Add Required validation (Events save)
        if ( SNFORMS.context.querySelector('#' + SNFORMS.event.submitter.getAttribute('id')).classList.contains('no-validate') ) {
        	SNFORMS.toggleAttrRequired();
        }
    },



    sendForm: () => {
		const { action, method } = SNFORMS.context;
		const body = new FormData(SNFORMS.context);

		fetch(action, {
			method,
			body,
			credentials: "same-origin",
			headers: {
		      "X-WP-Nonce": snJS.nonce,
		    },
		})
		.then((response) => response.json())
		.then((response) => {
			console.log(response);

			SNFORMS.context.parentElement.querySelectorAll('.notification').forEach((el) => {
				el.classList.add('is-hidden');
			});
			

			if ( response == 'success' || response.response == 'success' ) {
				SNFORMS.context.parentElement.querySelectorAll('.notification.is-success').forEach((el) => { 
					if ( SNFORMS.context.getAttribute('data-msg-success') != null )
						el.innerHTML = SNFORMS.context.getAttribute('data-msg-success');

					el.classList.remove('is-hidden');
				});

				if ( response.body_response != null && response.body_response.media != null)
					SNMEDIA.updateMedia( response.body_response.media );
				
				if ( SNFORMS.context.classList.contains('disappear-on-success') )
					SNFORMS.context.classList.add('is-hidden');

				if ( SNFORMS.context.getAttribute('data-redirect') )
					setTimeout( () => {
						let redirect = SNFORMS.context.getAttribute('data-redirect');
						document.location.replace(redirect);
						
					}, ( ( SNFORMS.context.getAttribute('data-redirect-timeout') != null ) ? SNFORMS.context.getAttribute('data-redirect-timeout') : 5000 ) );

			} else {
            	SNFORMS.context.parentElement.querySelectorAll('.notification.is-danger').forEach((el) => {
            		el.innerHTML = response.message;
					el.classList.remove('is-hidden');
				});

			}
			
			SNFORMS.context.querySelector('#' + SNFORMS.event.submitter.getAttribute('id')).classList.remove('is-loading');

			if ( SNFORMS.context.classList.contains('djht-validate-pristine') )
				SNFORMS.context.parentElement.scrollIntoView( {behavior: 'smooth'} );
		})
		.catch((error) => {
				console.log('Server error: ' + error);
				
				SNFORMS.context.parentElement.querySelectorAll('.notification').forEach((el) => {
					el.classList.add('is-hidden');
				});

				SNFORMS.context.parentElement.querySelectorAll('.notification.is-danger').forEach((el) => {
            		el.innerHTML = 'Ihre Anfrage konnte nicht verarbeitet werden. Bitte versuchen Sie es später erneut.';
					el.classList.remove('is-hidden');
				});

				SNFORMS.context.querySelector('#' + SNFORMS.event.submitter.getAttribute('id')).classList.remove('is-loading');
				SNFORMS.context.parentElement.scrollIntoView( {behavior: 'smooth'} );
			})
		;
    },



    toggleAttrRequired: () => {
    	let fields = SNFORMS.context.querySelectorAll('[required], .is-required');

    	fields.forEach( (field) => {
    		if ( field.classList.contains('is-required') ) {
    			field.classList.remove('is-required');
    			field.setAttribute('required', true);

    		} else {
    			field.classList.add('is-required');
    			field.removeAttribute('required');

    		}
    	});
    },



    initSubmissionCheck: () => {
    	let type = document.createElement('input');
        type.setAttribute('type', 'hidden');
        type.setAttribute('name', 'sn-fields[request-type]');
        type.setAttribute('value', 'submission-check');
        
        SNFORMS.context.append(type);

        let redirect = SNFORMS.context.getAttribute('data-redirect-submission-check');
        SNFORMS.context.setAttribute('data-redirect', redirect);
        
        let redirectTimeout = SNFORMS.context.getAttribute('data-redirect-timeout');
        SNFORMS.context.setAttribute('data-redirect-timeout', redirectTimeout);
    },
}

SNFORMS.onReady();
